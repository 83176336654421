var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-view',[_c('div',{staticClass:"close_wrap"},[_c('div',{staticClass:"btn_header"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.searchBtn}},[_vm._v("搜索")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.openModal}},[_vm._v("新增关闭类型")])],1),_c('a-table',{attrs:{"dataSource":_vm.dataSource,"columns":_vm.columns,"loading":_vm.loading,"pagination":false},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('a-popconfirm',{attrs:{"title":"确定删除?"},on:{"confirm":function($event){return _vm.removeItem(record.id)}}},[_c('a',{staticStyle:{"color":"red"}},[_vm._v("删除")])])}}])})],1),_c('a-modal',{attrs:{"title":"新增关闭类型","width":560,"okText":"确认新增"},on:{"cancel":function($event){_vm.visible = false},"ok":_vm.insertFn},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form',[_c('a-form-item',{attrs:{"label":"关键词","labelCol":{
          lg: { span: 6 },
          sm: { span: 24 },
          md: { span: 24 }
        },"wrapperCol":{ lg: { span: 12 }, sm: { span: 24 } }}},[_c('a-input',{staticStyle:{"width":"220px"},attrs:{"placeholder":"请填写关键词"},model:{value:(_vm.insertData.keyWord),callback:function ($$v) {_vm.$set(_vm.insertData, "keyWord", $$v)},expression:"insertData.keyWord"}})],1),_c('a-form-item',{attrs:{"label":"限制时间/分钟","labelCol":{
          lg: { span: 6 },
          sm: { span: 24 },
          md: { span: 24 }
        },"wrapperCol":{ lg: { span: 12 }, sm: { span: 24 } }}},[_c('a-input-number',{attrs:{"min":1},model:{value:(_vm.insertData.minutes),callback:function ($$v) {_vm.$set(_vm.insertData, "minutes", $$v)},expression:"insertData.minutes"}})],1),_c('a-form-item',{attrs:{"label":"数据1","labelCol":{
          lg: { span: 6 },
          sm: { span: 24 },
          md: { span: 24 }
        },"wrapperCol":{ lg: { span: 12 }, sm: { span: 24 } }}},[_c('a-input',{attrs:{"placeholder":"数据1"},model:{value:(_vm.insertData.data1),callback:function ($$v) {_vm.$set(_vm.insertData, "data1", $$v)},expression:"insertData.data1"}})],1),_c('a-form-item',{attrs:{"label":"数据2","labelCol":{
          lg: { span: 6 },
          sm: { span: 24 },
          md: { span: 24 }
        },"wrapperCol":{ lg: { span: 12 }, sm: { span: 24 } }}},[_c('a-input',{attrs:{"placeholder":"数据2"},model:{value:(_vm.insertData.data2),callback:function ($$v) {_vm.$set(_vm.insertData, "data2", $$v)},expression:"insertData.data2"}})],1),_c('a-form-item',{attrs:{"label":"关闭类型","labelCol":{
          lg: { span: 6 },
          sm: { span: 24 },
          md: { span: 24 }
        },"wrapperCol":{ lg: { span: 12 }, sm: { span: 24 } }}},[_c('a-select',{attrs:{"options":[
            { label: '关闭', value: 'CLOSE' },
            { label: '重推', value: 'REUSE' }
          ]},model:{value:(_vm.insertData.operate),callback:function ($$v) {_vm.$set(_vm.insertData, "operate", $$v)},expression:"insertData.operate"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }