<template>
  <page-view>
    <div class="close_wrap">
      <div class="btn_header">
        <a-button type="primary" @click="searchBtn">搜索</a-button>
        <a-button type="primary" @click="openModal">新增关闭类型</a-button>
      </div>
      <a-table :dataSource="dataSource" :columns="columns" :loading="loading" :pagination="false">
        <a-popconfirm
          title="确定删除?"
          slot="action"
          slot-scope="text, record"
          @confirm="removeItem(record.id)"
        >
          <a style="color: red">删除</a>
        </a-popconfirm>
      </a-table>
    </div>
    <a-modal
      v-model:visible="visible"
      title="新增关闭类型"
      :width="560"
      okText="确认新增"
      @cancel="visible = false"
      @ok="insertFn"
    >
      <a-form>
        <a-form-item
          label="关键词"
          :labelCol="{
            lg: { span: 6 },
            sm: { span: 24 },
            md: { span: 24 }
          }"
          :wrapperCol="{ lg: { span: 12 }, sm: { span: 24 } }"
        >
          <a-input
            v-model:value="insertData.keyWord"
            placeholder="请填写关键词"
            style="width: 220px"
          />
        </a-form-item>
        <a-form-item
          label="限制时间/分钟"
          :labelCol="{
            lg: { span: 6 },
            sm: { span: 24 },
            md: { span: 24 }
          }"
          :wrapperCol="{ lg: { span: 12 }, sm: { span: 24 } }"
        >
          <a-input-number v-model="insertData.minutes" :min="1" />
        </a-form-item>
        <a-form-item
          label="数据1"
          :labelCol="{
            lg: { span: 6 },
            sm: { span: 24 },
            md: { span: 24 }
          }"
          :wrapperCol="{ lg: { span: 12 }, sm: { span: 24 } }"
        >
          <a-input v-model="insertData.data1" placeholder="数据1" />
        </a-form-item>
        <a-form-item
          label="数据2"
          :labelCol="{
            lg: { span: 6 },
            sm: { span: 24 },
            md: { span: 24 }
          }"
          :wrapperCol="{ lg: { span: 12 }, sm: { span: 24 } }"
        >
          <a-input v-model="insertData.data2" placeholder="数据2" />
        </a-form-item>
        <a-form-item
          label="关闭类型"
          :labelCol="{
            lg: { span: 6 },
            sm: { span: 24 },
            md: { span: 24 }
          }"
          :wrapperCol="{ lg: { span: 12 }, sm: { span: 24 } }"
        >
          <a-select
            v-model="insertData.operate"
            :options="[
              { label: '关闭', value: 'CLOSE' },
              { label: '重推', value: 'REUSE' }
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </page-view>
</template>
<script>
import { PageView } from "@/layouts";
import { queryCloseList, removeCloseType, insertCloseType } from "@/api";
const operateType = {
  CLOSE: "关闭",
  REUSE: "重推"
};
const initData = {
  keyWord: undefined,
  minutes: 1,
  operate: "CLOSE",
  data1: "",
  data2: ""
};
export default {
  components: {
    PageView
  },
  data() {
    return {
      insertData: { ...initData },
      columns: [
        {
          title: "关键词",
          dataIndex: "keyWord",
          align: "center"
        },
        {
          title: "限制时间/分钟",
          dataIndex: "minutes",
          align: "center"
        },
        {
          title: "数据1",
          dataIndex: "data1",
          align: "center"
        },
        {
          title: "数据2",
          dataIndex: "data2",
          align: "center"
        },
        {
          title: "关闭类型",
          dataIndex: "operate",
          align: "center",
          customRender: text => operateType[text]
        },
        {
          title: "操作",
          dataIndex: "action",
          align: "center",
          scopedSlots: { customRender: "action" }
        }
      ],
      dataSource: [],
      loading: false,
      searchLoading: false,
      visible: false
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      const res = await queryCloseList();
      this.loading = false;
      this.dataSource = res.data;
    },
    async searchBtn() {
      this.searchLoading = true;
      await this.getList();
      this.searchLoading = false;
    },
    openModal() {
      this.insertData = { ...initData };
      this.visible = true;
    },
    async insertFn() {
      const { keyWord, minutes, operate, data1, data2 } = this.insertData;
      if (!keyWord) return this.$message.info("请填写关键词！");
      if (!minutes) return this.$message.info("请填写限制时间！");
      const res = await insertCloseType({
        keyWord,
        minutes,
        operate,
        data1,
        data2
      });
      if (res.success) {
        this.insertData = { ...initData };
        this.visible = false;
        this.getList();
      }
    },
    async removeItem(id) {
      const res = await removeCloseType(id);
      if (res.success) {
        this.getList();
      }
    }
  }
};
</script>
<style lang="less" scoped>
.close_wrap {
  background-color: #fff;
  padding: 24px;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  .btn_header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
  }
}
</style>
